<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-12">
        <div class="card bg-secondary shadow border-0">
          <div class="card-header bg-transparent">
            <div class="text-center text-muted">
              <h2>{{getTrans('messages.reset_passw')}}</h2>
              <small
                >{{getTrans('messages.email_passw_intro')}}</small
              >
              <form role="form">
                <base-input
                  class="input-group-alternative"
                  :placeholder="getTrans('messages.email')"
                  addon-left-icon="ni ni-email-83"
                >
                </base-input>
                <base-button type="primary" class="col-lg-6 col-12"
                  >{{getTrans('messages.reset_passw')}}</base-button
                >
                <router-link to="/login" class="text-light col-lg-6 col-12"
                  ><a href="#/login">{{getTrans('messages.back_to_login')}}</a></router-link
                >
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  }
};
</script>
<style></style>
